import {
  Box,
  chakra,
  Container,
  Link,
  Divider,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import AppStoreBadge from "./AppStoreBadge";
import PlayStoreBadge from "./PlayStoreBadge";

const Logo = (props: any) => {
  return <img src="/assets/images/logo.png" style={{ height: 40 }} />;
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  return (
    <>
      <Divider />
      <Box p={10} color={useColorModeValue("gray.700", "gray.200")}>
        <Container maxW={"6xl"} as={Stack}>
          <SimpleGrid
            templateColumns={{ sm: "1fr 1fr", md: "3fr 1fr 1fr 1fr" }}
          >
            <Stack spacing={6}>
              <Box>
                <Logo color={useColorModeValue("gray.700", "white")} />
              </Box>
              <Text fontSize={"md"}>
                CÔNG TY CỔ PHẦN PHÁT TRIỂN THƯƠNG MẠI VÀ DỊCH VỤ WESMART
              </Text>
              <Text fontSize={"sm"}>
                Hotline: 0816.333.868 - 0986.858.555 <br />
                Email: sale@wesmart.vn{" "}
              </Text>
              <Text fontSize={"sm"}>© 2022 WeSmart.</Text>
              <Stack direction={"row"} spacing={6}>
                <SocialButton label={"Twitter"} href={"#"}>
                  <FaTwitter />
                </SocialButton>
                <SocialButton label={"YouTube"} href={"#"}>
                  <FaYoutube />
                </SocialButton>
                <SocialButton label={"Instagram"} href={"#"}>
                  <FaInstagram />
                </SocialButton>
              </Stack>
            </Stack>
            <Stack align={"flex-start"}>
              <ListHeader>Giới thiệu</ListHeader>
              <Link href={"#"}>Về chúng tôi</Link>
              <Link href={"#"}>Tin tức</Link>
              <Link href={"#"}>Liên hệ</Link>
              {/* <Link href={'#'}>Pricing</Link>
                        <Link href={'#'}>Testimonials</Link> */}
            </Stack>
            <Stack align={"flex-start"}>
              <ListHeader>Hỗ trợ</ListHeader>
              <Link href={"#"}>Trợ giúp</Link>
              <Link href={"#"}>Điều khoản sử dụng</Link>
              {/* <Link href={'#'}>Legal</Link> */}
              {/* <Link href={'#'}>Privacy Policy</Link> */}
              {/* <Link href={'#'}>Satus</Link> */}
            </Stack>
            <Stack align={"flex-start"}>
              {/* <ListHeader>Install App</ListHeader> */}
              <AppStoreBadge />
              <PlayStoreBadge />
              {/* <ListHeader>Stay up to date</ListHeader>
                            <Stack direction={'row'}>
                                <Input
                                    placeholder={'Your email address'}
                                    bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                                    border={0}
                                    _focus={{
                                        bg: 'whiteAlpha.300',
                                    }}
                                />
                                <IconButton
                                    bg={useColorModeValue('green.400', 'green.800')}
                                    color={useColorModeValue('white', 'gray.800')}
                                    _hover={{
                                        bg: 'green.600',
                                    }}
                                    aria-label="Subscribe"
                                    icon={<BiMailSend />}
                                />
                            </Stack> */}
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
}
