import { ReactElement, ReactNode } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Heading,
  Container,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import {
  BsFillHandThumbsUpFill,
  BsAlarmFill,
  BsFillTrophyFill,
  BsFillHeartFill,
} from "react-icons/bs";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
  children: ReactNode;
}

const StepWidget = ({ title, text, icon, children }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600} fontSize={18}>
        {title}
      </Text>
      <Text color={"gray.600"}>{text}</Text>
      {children}
    </Stack>
  );
};

export default function LMSWithEnterprise() {
  return (
    <Box as={Container} maxW={"6xl"} p={10} id="enterprise">
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Ứng dụng azStudy trong doanh nghiệp</Heading>
        <Text color={"gray.500"} fontSize={"large"} textAlign={"justify"}>
          Đào tạo chuyên môn nghiệp vụ chiếm vai trò quan trọng nhất trong doanh
          nghiệp, giúp doanh nghiệp tạo ra đội ngũ lao động tay nghề cao, có
          năng lực nghề nghiệp và phát huy mạnh mẽ mọi giá trị nền tảng về sản
          phẩm dịch vụ. Đào tạo chuyên môn nghiệp vụ phải diễn ra thường xuyên
          và liên tục, luôn cần sự chuẩn xác của nội dung truyền tải và tính kịp
          thời, đổi mới.
        </Text>
      </Stack>
      <br />
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
        <StepWidget
          icon={
            <Icon as={BsFillHandThumbsUpFill} color="green.500" w={10} h={10} />
          }
          title={"Tuyển dụng"}
          text={""}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đánh giá kiến thức chuyên môn/ nghiệp vụ
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đánh giá tiếng anh TOEIC
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đánh giá EQ/IQ…
            </ListItem>
          </List>
        </StepWidget>
        <StepWidget
          icon={<Icon as={BsAlarmFill} color="green.500" w={10} h={10} />}
          title={"Thử việc"}
          text={""}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đào tạo hội nhập
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đào tạo kiến thức chung
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Thi đánh giá kết thúc thử việc
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Cấp phát chứng chỉ
            </ListItem>
          </List>
        </StepWidget>
        <StepWidget
          icon={<Icon as={BsFillHeartFill} color="green.500" w={10} h={10} />}
          title={"Chính thức"}
          text={""}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Khảo sát định kỳ
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đào tạo kiến thức chung
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đào tạo về sản phẩm, quy trình, chính sách
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đào tạo kiến thức chuyên môn/ nghiệp vụ
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đào tạo phát triển theo nhu cầu cá nhân
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đánh giá sát hạch tháng/ quý/ năm …
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Cấp phát chứng chỉ
            </ListItem>
          </List>
        </StepWidget>
        <StepWidget
          icon={<Icon as={BsFillTrophyFill} color="green.500" w={10} h={10} />}
          title={"Thăng tiến"}
          text={""}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Khảo sát nhu cầu đào tạo phát triển cá nhân
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Xếp hạng thi đua
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đào tạo theo lộ trình chức danh
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đánh giá nâng lương nâng bậc
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Thư viện số
            </ListItem>
          </List>
        </StepWidget>
      </SimpleGrid>
    </Box>
  );
}
