import { ReactElement, ReactNode } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Heading,
  Container,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { FcAssistant, FcDonate, FcInTransit } from "react-icons/fc";
import { MdCheckCircle } from "react-icons/md";
import {
  BsFillHandThumbsUpFill,
  BsAlarmFill,
  BsFillTrophyFill,
  BsFillHeartFill,
  BsFillLightningChargeFill,
} from "react-icons/bs";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
  children: ReactNode;
}

const StepWidget = ({ title, text, icon, children }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
      {children}
    </Stack>
  );
};

export default function LMSWithSchool() {
  return (
    <Box as={Container} maxW={"6xl"} p={10} id="school">
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>
          Ứng dụng azStudy trong cho Trường học, Trung tâm, Tổ chức giáo dục
        </Heading>
        <Text color={"gray.500"} fontSize={"large"} textAlign={"justify"}>
          Với sự phát triển của công nghệ như hiện nay, việc học tập và đào tạo
          không chỉ giới hạn ở trên giảng đường, giới hạn bởi không gian và thời
          gian, người học có thể tiếp cận bài giảng mọi nơi. Ngoài ra nó còn
          được tích hợp video và các công cụ giúp lưu lại lời giảng và giáo án,
          để người học có thể lưu trữ và tham khảo lại.
        </Text>
      </Stack>
      <br />
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <StepWidget
          icon={
            <Icon as={BsFillHandThumbsUpFill} color="green.500" w={10} h={10} />
          }
          title={"Đánh giá đầu vào"}
          text={""}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Khảo sát nhu cầu
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Đánh giá kiến thức
            </ListItem>
          </List>
        </StepWidget>
        <StepWidget
          icon={<Icon as={BsFillTrophyFill} color="green.500" w={10} h={10} />}
          title={"Ôn luyện kiến thức"}
          text={""}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Tổ chức các lớp bồi dưỡng, ôn tập kiến thức
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Thư viện số
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Tổ chức kỳ thi, bộ đề ôn luyện
            </ListItem>
          </List>
        </StepWidget>
        <StepWidget
          icon={
            <Icon
              as={BsFillLightningChargeFill}
              color="green.500"
              w={10}
              h={10}
            />
          }
          title={"Đánh giá chính thức"}
          text={""}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Tổ chức các lớp học chính thức
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Tổ chức kỳ thi chính thức
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Cấp phát chứng chỉ
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Xếp hạng thi đua
            </ListItem>
          </List>
        </StepWidget>
      </SimpleGrid>
    </Box>
  );
}
