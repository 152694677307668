import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Container,
  Code,
  Grid,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import Hero from "./components/Hero";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LMSWithEnterprise from "./components/LMSWithEnterprise";
import LMSWithSchool from "./components/LMSWithSchool";
import Course from "./components/features/Course";
import Assignment from "./components/features/Assignment";
import PriceList from "./components/PriceList";
import "react-image-gallery/styles/css/image-gallery.css";
import Gallery from "./components/Gallery";
import FAQ from "./components/FAQ";
import Features from "./components/features/Features";
import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const theme = extendTheme(withDefaultColorScheme({ colorScheme: "green" }));

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="left" fontSize="md">
      <Grid minH="100vh" p={0}>
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        <VStack spacing={0}>
          <Container maxW={"full-width"} p={0}>
            <Header />
            <Hero />
            <Box bg="gray.50">
              <LMSWithEnterprise />
            </Box>
            <Box>
              <LMSWithSchool />
            </Box>
            <Box bg="gray.50">
              <Course />
            </Box>
            <Assignment />
            <Box bg="gray.50">
              <Features />
            </Box>
            {/* <PriceList /> */}
            <Gallery />
            <br />
            {/* <FAQ /> */}
            <Footer />
          </Container>
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
);
