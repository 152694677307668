import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Container,
  Heading,
} from "@chakra-ui/react";
import { FcAssistant, FcDonate, FcInTransit } from "react-icons/fc";
import { BsFillTrophyFill } from "react-icons/bs";
import {
  FaBook,
  FaNewspaper,
  FaChartPie,
  FaBox,
  FaComment,
  FaTv,
  FaKey,
  FaFingerprint,
  FaShieldAlt,
} from "react-icons/fa";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return (
    <Box as={Container} maxW={"6xl"} p={10} id="feature">
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Chức năng nổi bật khác</Heading>
        <Text color={"gray.600"} fontSize={"xl"}>
          {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
      sed diam voluptua. */}
        </Text>
      </Stack>
      <Box p={4}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            icon={
              <Icon as={BsFillTrophyFill} color="green.500" w={10} h={10} />
            }
            title={"Quản lý Chứng chỉ"}
            text={
              "Quản lý chứng chỉ bên ngoài của học viên. Cấp phát chứng chỉ tự động cho các khóa online trên hệ thống. Học viên có thể xem, tải về các chức chỉ được cấp phát"
            }
          />
          <Feature
            icon={<Icon as={FaBook} color="green.500" w={10} h={10} />}
            title={"Quản lý Thư viện tài liệu"}
            text={
              "Thư viện có thể được chia sẻ công khai ra cho người dùng tra cứu như một thư viện số hoặc được đưa vào làm học liệu cho các khóa học. "
            }
          />
          <Feature
            icon={<Icon as={FaNewspaper} color="green.500" w={10} h={10} />}
            title={"Quản lý Tin tức, Bản tin đào tạo"}
            text={
              "Cho phép soạn và đăng các Tin tức, Bản tin đào tạo truyền thông nội bộ."
            }
          />
          <Feature
            icon={<Icon as={FaBox} color="green.500" w={10} h={10} />}
            title={"Phân miền dữ liệu"}
            text={
              "Hệ thống cho phép phách tách dữ liệu theo từng site đơn vị. Dữ liệu đào tạo chung cho toàn công ty và dữ liệu của từng đơn vị được tách riêng. "
            }
          />
          <Feature
            icon={<Icon as={FaChartPie} color="green.500" w={10} h={10} />}
            title={"Khảo sát"}
            text={
              "Công cụ thu thập ý kiến, phản hồi của học viên, hỗ trợ các loại định dạng câu hỏi phổ biến."
            }
          />
          <Feature
            icon={<Icon as={FaComment} color="green.500" w={10} h={10} />}
            title={"Thảo luận và Thông báo"}
            text={
              "Cung cấp các công cụ giao tiếp như thảo luận, thông báo, nhắc nhở qua email."
            }
          />
          <Feature
            icon={<Icon as={FaShieldAlt} color="green.500" w={10} h={10} />}
            title={"Hạn chế gian lận thi"}
            text={
              "Hệ thống cho phép cấu hình chụp ảnh khi thí sinh vào thi, chuyển tab trình duyệt, đặt mật khẩu vào thi, không cho phép đăng nhập nhiều thiết bị đồng thời."
            }
          />
          <Feature
            icon={<Icon as={FaTv} color="green.500" w={10} h={10} />}
            title={"Giám sát kỳ thi"}
            text={
              "Hệ thống cho phép giám sát kỳ thi theo thời gian thực đồng thời lưu vết các sự kiện thí sinh trong quá trình làm bài thi."
            }
          />
          <Feature
            icon={<Icon as={FaFingerprint} color="green.500" w={10} h={10} />}
            title={"Điểm danh học Offline"}
            text={
              "Hệ thống cho phép điểm danh học Offline bằng QR code, chụp ảnh và nhận diện khuôn mặt"
            }
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
}
