import ImageGallery from "react-image-gallery";
import {
  Container,
  Stack,
  Flex,
  Divider,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";

const images = [
  {
    original: "/assets/images/course-module.png",
    thumbnail: "/assets/images/course-module.png",
  },
  {
    original: "/assets/images/exam-module.png",
    thumbnail: "/assets/images/exam-module.png",
  },
  {
    original: "/assets/images/home.png",
    thumbnail: "/assets/images/home.png",
  },
  {
    original: "/assets/images/hero.png",
    thumbnail: "/assets/images/hero.png",
  },
  {
    original: "/assets/images/news-module.png",
    thumbnail: "/assets/images/news-module.png",
  },
  {
    original: "/assets/images/record.png",
    thumbnail: "/assets/images/record.png",
  },
  {
    original: "/assets/images/profile.png",
    thumbnail: "/assets/images/profile.png",
  },
];

export default function Gallery() {
  return (
    <>
      <Divider />
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"} id="gallery">
        <Text color={"gray.600"} fontSize={"xl"}>
          {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                    sed diam voluptua. */}
        </Text>
      </Stack>
      <ImageGallery items={images} />
    </>
  );
}
