import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Flex,
  Grid,
  GridItem,
  Image,
  chakra,
  Center,
  Text,
  Divider,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import {} from "@chakra-ui/react";

interface FeatureProps {
  heading: string;
  text: string;
}

const Feature = ({ heading, text }: FeatureProps) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  );
};

// Replace test data with your own
const features = [
  {
    id: 1,
    title: "Ngân hàng câu hỏi ",
    text: "Cho phép nhập lên hệ thống kho ngân hàng câu hỏi, hỗ trợ nhiều kiểu loại câu hỏi như trắc nghiệm, tự luận, ghi âm. Cho phép Import với nhiều định dạng Excel, Zip, Aiken.",
  },
  {
    id: 2,
    title: "Quản lý bộ đề thi",
    text: "Bộ đề thi với cấu trúc đề thi tùy biến, linh hoạt. Cấu hình câu hỏi đính danh hoặc ngẫu nhiên. Sinh đề ngẫu nhiên, xem trước đề, in đề thi offline. Hỗ trợ nhiều loại thang điểm như 10, 100, TOEIC …",
  },
  {
    id: 3,
    title: "Tổ chức thi",
    text: "Hỗ trợ nhiều hình thức tổ chức thi như trắc nghiệm, tự luận, VSTEP, TOEIC... Hỗ trợ đầy đủ quy trình: tổ chức thi > phân nhóm chấm > chấm điểm > phúc khảo chấm lại > ghép điểm.",
  },
];

export default function Features() {
  return (
    <Box as={Container} maxW={"6xl"} p={10} id="exam">
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Phân hệ chức năng Tổ chức thi</Heading>
        <Text color={"gray.600"} fontSize={"xl"}>
          {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. */}
        </Text>
      </Stack>
      <Box as={Container} maxW="7xl" mt={14} p={4}>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          gap={4}
        >
          <GridItem colSpan={1}>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
                md: "repeat(1, 1fr)",
              }}
              gap={{ base: "2", sm: "3", md: "4" }}
            >
              {features.map((feature) => (
                <Feature heading={feature.title} text={feature.text} />
              ))}
            </Grid>
          </GridItem>
          <GridItem>
            <Flex>
              <Image
                rounded={"md"}
                alt={"Tổ chức thi"}
                src={"/assets/images/exam-module.png"}
                objectFit={"cover"}
              />
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}
