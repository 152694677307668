import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Flex,
  Grid,
  Image,
  GridItem,
  chakra,
  Center,
  Text,
  Divider,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import {} from "@chakra-ui/react";

interface FeatureProps {
  heading: string;
  text: string;
}

const Feature = ({ heading, text }: FeatureProps) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  );
};

// Replace test data with your own
const features = [
  {
    id: 1,
    title: "Quản lý khóa học",
    text: "Cho phép tổ chức các khóa học với nhiều hình thức như online/ offline/ kết hợp (blended learning) cho phép đăng ký, không cho đăng ký, tính phí .... hỗ trợ đa dạng các hoạt động như khảo sát học viên, bài giảng, tài liệu, kiểm tra, giao bài tập, thảo luận, điểm danh QRcode, thông báo",
  },
  {
    id: 2,
    title: "Quản lý lộ trình học tập",
    text: "Thiết lập lộ trình học tập cho từng nhóm đối tượng chức danh khác nhau.    Học viên hoàn thành các khóa học theo lộ trình được thiết lập. Xem các nội dung cần học cho vị trí tiếp theo",
  },
  {
    id: 3,
    title: "Xếp hạng thi đua",
    text: "Tổ chức các đợt thi đua xếp hạng (gamification) học viên dựa trên các hoạt động thi đua tại các khóa học hoặc kỳ thi. Học viên sẽ được xếp hạng và nhận các huy hiệu tương ứng với thành tính đạt được.",
  },
];

export default function Features() {
  return (
    <Box as={Container} maxW={"6xl"} p={10} id="course">
      <Stack spacing={4} as={Container} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Phân hệ chức năng Học tập</Heading>
        <Text color={"gray.600"} fontSize={"xl"}>
          {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. */}
        </Text>
      </Stack>
      <Box mt={14} p={4}>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          gap={4}
        >
          <GridItem colSpan={1}>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
                md: "repeat(1, 1fr)",
              }}
              gap={{ base: "2", sm: "3", md: "4" }}
            >
              {features.map((feature) => (
                <Feature heading={feature.title} text={feature.text} />
              ))}
            </Grid>
          </GridItem>
          <GridItem alignContent={"center"}>
            <Flex>
              <Image
                style={{ alignSelf: "center" }}
                rounded={"md"}
                alt={"feature image"}
                src={"/assets/images/course-module.png"}
                objectFit={"cover"}
              />
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}
